// src/components/LinksPage.js
import React from 'react';
const imgGo = require("../images/icon-go-white.svg").default;
const imgAppEngagementReporting =
    require("../images/app-engagement-reporting.svg").default;

const LinksPage = () => {
    return (
        <div className={`o-app-launcher o-app-launcher--shown`} id="app-launcher">
            {/* <div className="o-app-launcher__overlay"></div> */}
            {/* <button className="o-app-launcher__close-btn" id="launcher-close"
                onClick={closeClickedHandler}>
                <img className="o-app-launcher__close-icon" src={iconCloseSquare} />
            </button> */}
            <div className="o-app-launcher__container">
                <h2 className="o-app-launcher__title"></h2>

                <div className="o-app-launcher__container">
                    {/* <NavLink
                        className="o-app-launcher__app"
                        to={app.path}
                        onClick={() => {}}
                        key={index}
                    >
                        <img className="o-app-launcher__app-icon" src={app.imgSrc} />
                        <span className="o-app-launcher__app-title">{app.appName}</span>
                        <img className="o-app-launcher__go-icon" src={imgGo} />
                    </NavLink> */}
                    <a className='o-app-launcher__app' href='https://app.powerbi.com/'>
                        <img className="o-app-launcher__app-icon" src={imgAppEngagementReporting} />
                        <span className="o-app-launcher__app-title">Professional Svcs Reporting</span>
                        <img className="o-app-launcher__go-icon" src={imgGo} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LinksPage;
