// src/App.js
import React, { useEffect, useState } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./configs/authConfig";
import LinksPage from "./components/LinksPage";

import "./styles/toolkit.css";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const App = () => {
	const { instance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [isInitialized, setIsInitialized] = useState(true);
	const [error, setError] = useState(null);

	// useEffect(() => {
	// 	const initialize = async () => {
	// 		try {
	// 			await instance.initialize();
	// 			setIsInitialized(true);
	// 		} catch (error) {
	// 			console.error("MSAL initialization error:", error);
	// 		}
	// 	};

	// 	initialize();
	// }, [instance]);

	useEffect(() => {
		if (!isAuthenticated && isInitialized && inProgress === "none") {
			// // instance.loginRedirect(loginRequest).catch((e) => {
			// // 	console.error(e);
			// // });
			// instance.handleRedirectPromise().then((authResult) => {
			// 	if (accounts.length > 0) {
			// 		// Attempt to get a token if there are accounts available
			// 		instance.acquireTokenRedirect(loginRequest).catch((e) => {
			// 			console.error(e);
			// 		});
			// 	} else {
			// 		try {
			// 			// Initiate login redirect if there are no accounts
			// 			instance.loginRedirect(loginRequest).catch((e) => {
			// 				console.error(e);
			// 			});
			// 		} catch (loginError) {}
			// 	}
			// });
			login();
			// instance
			// 	.handleRedirectPromise()
			// 	.then((tokenResponse) => {
			// 		if (!tokenResponse) {
			// 			const accounts = instance.getAllAccounts();
			// 			if (accounts.length === 0) {
			// 				// No user signed in
			// 				instance.loginRedirect();
			// 			}
			// 		} else {
			// 			// Do something with the tokenResponse
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		// Handle error
			// 		console.error(err);
			// 	});
		} else if (isAuthenticated) {
			// Redirect to "https://app.powerbi.com/"
			window.location.href = "https://app.powerbi.com/";
		}
	}, [isAuthenticated, isInitialized, inProgress, instance]);

	const login = async () => {
		// await instance.handleRedirectPromise();

		console.log(instance.getAllAccounts());

		if (accounts.length > 0) {
			// Attempt to get a token if there are accounts available
			await acquireToken(loginRequest, accounts[0]);
			// Only update the graph access token state if we received a token
		} else {
			try {
				// Initiate login redirect if there are no accounts
				await instance.acquireTokenRedirect(loginRequest);
			} catch (loginError) {
				// Handle errors during login redirect
				setError(loginError);
				console.error("Login redirect error", loginError);
				try {
					await instance.loginRedirect();
				} catch (error) {
					console.error(error);
				}
			}
		}
	};

	const acquireToken = async (requestConfig, account) => {
		try {
			// Attempt to acquire token silently
			const response = await instance.acquireTokenSilent({
				...requestConfig,
				account,
				forceRefresh: false,
			});
			return response.accessToken;
		} catch (error) {
			// Silent token acquisition failed, likely due to a required user interaction
			if (error instanceof InteractionRequiredAuthError) {
				try {
					// Fallback to an interactive method
					const response = await instance.acquireTokenRedirect(
						requestConfig
					);
					return response.accessToken;
				} catch (interactiveError) {
					// Handle errors during interactive token acquisition
					setError(interactiveError);
					console.error(
						"Interactive token acquisition error",
						interactiveError
					);
					return null;
				}
			} else {
				// Handle other types of errors during token acquisition
				setError(error);
				console.error("Silent token acquisition error", error);
				return null;
			}
		}
	};

	return (
		<div className="App">
			{isAuthenticated ? (
				<LinksPage />
			) : error ? (
				// <div>{{...error}}</div>
				<div>Something went wrong.</div>
			) : (
				<div>Redirecting to login...</div>
			)}
		</div>
	);
};

export default App;
